import React from "react"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"

const ManutenzionePage = () => {
  return (
    <Layout title="Manutenzione">
      <Container>
        <h1>Sito in manutenzione</h1>
        <p>
          La pagina che sta cercando è in manutenzione, le chiediamo di
          attendere
        </p>
      </Container>
    </Layout>
  )
}

export default ManutenzionePage
